.react-datepicker-wrapper {
  display: block !important;
}

.react-datepicker__input-container {
  position: relative;
  display: block !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.5rem;
  line-height: 2.5rem;
  font-size: 1.2rem;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  font-size: 1.4rem;
  line-height: 1.4rem;
}

.react-datepicker__month-select,
.react-datepicker__year-dropdown-container--select {
  font-size: 1.2rem;
}

.react-datepicker-popper,
.react-datepicker__day {
  z-index: 1000;
}
