html,
body {
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
}

html.goodme {
  /* Terrible hack for goodme */
  font-size: 10px;
}

html.goodme header p {
  /* Terrible hack for goodme */
  font-size: 14px;
  line-height: 21px;
}

html.goodme header input {
  /* Terrible hack for goodme */
  appearance: none;
  background: inherit;
  border-radius: 99px;
  /* border: 1px solid #e2e8f0; */
  font-size: 16px;
  height: 40px;
  min-width: 0px;
  outline-offset: 2px;
  outline: 2px solid transparent;
  padding-inline-end: 16px;
  padding-inline-start: 20px;
  padding-left: 20px;
  position: relative;
  width: 100%;
}

/* Terrible hack for goodme */
/* html.goodme header nav .item_container_button {
  width: 80px;
} */

html.goodme header nav .item_container_menu {
  min-width: 224px;
}

html.goodme header div.chakra-input__left-element {
  /* Terrible hack for goodme */
  height: 40px;
  width: 40px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*  split translations on \n */
#root,
div[id^='chakra-modal'] {
  white-space: pre-line;
}

#root {
  height: 100%;
  width: 100%;
}

/* *:focus {
  box-shadow: none !important;
} */

p,
span {
  color: #acacac;
}

*[data-focus] {
  box-shadow: none !important;
}

.navlink {
  align-items: center;
  color: #acacac;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.navlink p {
  color: #acacac;
  font-weight: 600;
}

.navlink.active {
  color: #000 !important;
}

.navlink.active p {
  color: #000;
}

.navlink-horizontal {
  align-items: center;
  color: #acacac;
  display: flex;
  font-weight: 600;
  height: 100%;
  width: 100%;
}

.navlink-horizontal.active {
  color: #000;
}

.navlink-horizontal.active p {
  color: #000;
}

.navlink:hover {
  background-color: #f8f8f8;
}

.navlink.no-hover {
  background-color: #ffffff;
}

table {
  width: 100%;
}

table thead {
  border-bottom: 1px solid #e2e2e2;
}

th {
  padding-bottom: 15px !important;
}

/* tbody tr,
tbody td { */
/* border-bottom: 1px solid #e2e2e2 !important; */
/* } */

tr:nth-of-type(odd) td {
  background: none !important;
}

td {
  padding: 23px 24px;
}

th:last-child div {
  justify-content: flex-end;
}

.noAction th:last-child div {
  justify-content: flex-start;
}

.stripedTable thead {
  border-bottom: 1px solid #e2e2e2;
}

.stripedTable tbody tr {
  border-bottom: 1px solid #e2e2e2;
}

.stripedTable tr {
  box-shadow: none !important;
  border-bottom: 1px solid #e2e2e2;
}

.stripedTable th {
  padding-bottom: 15px !important;
}

.stripedTable tr:nth-of-type(odd) td {
  background: transparent !important;
}

.stripedTable tr:nth-of-type(even) {
  background-color: #f7fafc;
}

.stripedTable td {
  padding: 20px 16px;
}

.stripedTable.noAction td {
  padding: 20px 16px !important;
}

.stripedTable td:last-child {
  padding: 16px 0px;
}

.stripedTable th:last-child {
  padding-right: 0px;
}

.stripedTable.noAltStripes tr:nth-of-type(even) {
  background: transparent !important;
}

.roundedTable {
  border-radius: 10px;
  overflow: hidden;
}

.roundedTable thead {
  display: none;
}

.roundedTable tbody tr {
  background-color: #e2e2e2;
  box-shadow: none !important;
  border-bottom: 1px solid #acacac;
}

.roundedTable tbody tr:last-child {
  border-bottom: none;
}

.chakra-alert__icon svg path {
  fill: #fff !important;
}

.react-tel-input [disabled] {
  background-color: rgba(239, 239, 239, 0.3);
  cursor: default;
}

.react-tel-input .country-list {
  list-style: none;
  position: absolute;
  z-index: 15;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 10px rgb(0 0 0 / 35%);
  background-color: #fff;
  width: 300px;
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 0 0 3px 3px;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent;
  border-radius: 3px 0 0 0;
}

.react-tel-input .country-list .country-name {
  color: rgb(51, 51, 51);
  margin-right: 6px;
}

.react-tel-input .country-list .country .dial-code {
  color: #6b6b6b;
}

.puselect {
  width: 100%;
}

.puselect > div > div {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.puselect > div > div::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.search-location-input {
  display: flex;
  width: 484px;
  border-radius: 24px;
  border: 1px solid #d8d8d8;
}

.search-location-input:focus-within {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-color: rgba(223, 225, 229, 0);
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

.search-location-input:hover {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  border-color: rgba(223, 225, 229, 0);
}

.search-location-input input {
  height: 32px;
  width: 100%;
  padding: 8px 12px;
  border: none;
  font-size: 16px;
  border-radius: 24px;
}

.search-location-input input:focus {
  outline: none;
}

.pac-container {
  font-family: 'Nunito Sans', sans-serif;
  box-shadow: 0 2px 6px rgb(217 217 217 / 100%);
}

.uploader-button {
  /* max-width: 440px; */
  /* font-size: 1.25rem; */
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.625rem 0rem;
}

.uploader-text {
  /* width: 300px; */
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0.625rem 0rem;
}

.segmented-control.ContainerStyle {
  width: 500px;
  color: #fcb731;
  border-radius: 10px;
  margin: 0 auto;
}

.segmented-control.ContainerStyle > label {
  line-height: 2rem;
  font-weight: bold;
}

.formTicket {
  width: 100%;
}

.parsedZendeskContainer p {
  color: #000;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
}

.parsedZendeskContainer ul,
.parsedZendeskContainer ol {
  padding-left: 50px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.4);
}

.button-impersonate {
  height: 0;
  background-color: rgb(251, 202, 202);
  padding: 0 10px 4px !important;
}

.react-datepicker-popper .react-datepicker__header__dropdown {
  display: flex;
  justify-content: space-between;
  padding: 15px 15px 0;
}

.react-datepicker-popper .react-datepicker__month-dropdown-container {
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  background-color: #fff;
  padding: 3px;
}

.react-datepicker-popper .react-datepicker__year-dropdown-container {
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  background-color: #fff;
  padding: 3px;
}

#ot-sdk-btn.ot-sdk-show-settings {
  padding: 0 !important;
  color: inherit !important;
  font-size: var(--chakra-fontSizes-xs) !important;
  background-color: transparent !important;
  border: none !important;
  cursor: pointer;
  display: block;
}
